import React from 'react';
import ReactDOM from 'react-dom/client';

import header from './scripts/header';
import about from './scripts/about';
// import food from './scripts/food';
import nutrition from './scripts/nutrition';
import pool from './scripts/pool';
import entertainment from './scripts/entertainment';
import beach from './scripts/beach';
import rooms from './scripts/rooms';
import reviews from './scripts/reviews';
import question from './scripts/question';
import stocks from './scripts/stocks';

import { App } from './App';

import './styles/main.scss';
import { Quiz, QuizProvider } from './features/quiz';
import { Question, QuestionProvider } from 'features/question';
import { AppProvider } from 'features/app/contexts/app-context';
import { TextModal } from 'features/app';
import { ThanksModal } from 'features/app/components/thanks-modal';

header.init();
about.init();
// food.init();
nutrition.init();
pool.init();
entertainment.init();
beach.init();
rooms.init();
reviews.init();
question.init();
stocks.init();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
);

const quizRoot = ReactDOM.createRoot(
  document.getElementById('quiz') as HTMLElement,
);
quizRoot.render(
  <React.StrictMode>
    <AppProvider>
      <QuizProvider>
        <Quiz />
        <TextModal />
        <ThanksModal />
      </QuizProvider>
    </AppProvider>
  </React.StrictMode>,
);

const questionRoot = ReactDOM.createRoot(
  document.getElementById('question') as HTMLElement,
);
questionRoot.render(
  <React.StrictMode>
    <AppProvider>
      <QuestionProvider>
        <Question />
        <TextModal />
        <ThanksModal />
      </QuestionProvider>
    </AppProvider>
  </React.StrictMode>,
);
