import { Cookie } from 'common/elements/cookie';
import { AddReview } from 'features/add-review';
import { AddReviewProvider } from 'features/add-review/contexts/callback-context';
import { TextModal, useAppContext } from 'features/app';
import { ThanksModal } from 'features/app/components/thanks-modal';
import {
  AppActions,
  TextModalType,
} from 'features/app/contexts/app-context/types';
import { Booking } from 'features/booking/components/booking';
import { BookingProvider } from 'features/booking/contexts/booking-context';
import { Callback } from 'features/callback';
import { CallbackProvider } from 'features/callback/contexts/callback-context';
import { Review } from 'features/review/components/review';
import { ReviewProvider } from 'features/review/contexts/review-context';
import { Stock } from 'features/stock/components/stock';
import { StockProvider } from 'features/stock/contexts/stock-context';
import { useEffect } from 'react';

const policyButton =
  document.querySelector<HTMLButtonElement>('#footer-policy');
const rulesButton = document.querySelector<HTMLButtonElement>('#footer-rules');
const cookieButton =
  document.querySelector<HTMLButtonElement>('#footer-cookie');
const principlesButton =
  document.querySelector<HTMLButtonElement>('#footer-principles');
const requisitesButton =
  document.querySelector<HTMLButtonElement>('#footer-requisites');
const buttons = [
  policyButton,
  rulesButton,
  cookieButton,
  principlesButton,
  requisitesButton,
];

export function App() {
  const [, dispatch] = useAppContext();

  useEffect(() => {
    function toggle(this: HTMLButtonElement) {
      let type: TextModalType = 'policy';
      if (this.id === 'footer-rules') {
        type = 'rules';
      } else if (this.id === 'footer-cookie') {
        type = 'cookie';
      } else if (this.id === 'footer-principles') {
        type = 'principles';
      } else if (this.id === 'footer-requisites') {
        type = 'requisites';
      }

      dispatch({ type: AppActions.ToggleTextModal, payload: type });
    }

    buttons.forEach((button) => button?.addEventListener('click', toggle));

    return () =>
      buttons.forEach((button) => button?.removeEventListener('click', toggle));
  }, []);

  return (
    <>
      <CallbackProvider>
        <Callback />
      </CallbackProvider>

      <BookingProvider>
        <Booking />
      </BookingProvider>

      <ReviewProvider>
        <Review />
      </ReviewProvider>

      <StockProvider>
        <Stock />
      </StockProvider>

      <AddReviewProvider>
        <AddReview />
      </AddReviewProvider>

      <TextModal />
      <ThanksModal />
      <Cookie />
    </>
  );
}

// const openFromReact = (
//   selector: string,
//   callback: (event: MouseEvent) => void
// ): void => {
//   document
//     .querySelectorAll<HTMLElement>(selector)
//     .forEach((el) => el.addEventListener('click', callback));
// };
